import './public.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Col, Row, Spinner } from 'reactstrap';
import { ApiService } from '../../services/apiService';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ReactPlayer from "react-player";
import authService from "../../components/api-authorization/AuthorizeService";
import { PlayIcon } from "../../components/PlayIcon"

export const LandingPage = () => {
    const [template, setTemplate] = useState();
    const [loading, setLoading] = useState(false);
    const [started, setStarted] = useState(true);
    const [videoTop, setVideoTop] = useState('0rem');
    const [heightHandle, setheightHandle] = useState(800);
    const { id } = useParams();
    let { width, height } = useWindowDimensions();
    const playerRef = useRef(null);
    const [videoWidth, setVideoWidth] = useState(0);
    const [videoHeight, setVideoHeight] = useState(0);
    const [project, setProject] = useState(null);
    const [videoImageUrl, setVideoImageUrl] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);

    // Adjusting videoWidth and videoHeight based on aspect ratio
    useEffect(() => {
        if (!template) return;

        if (width <= 1440) {
            height = 400;
            setVideoTop('1rem');
        } else if (height > heightHandle) {
            height = 560;
            setVideoTop('2rem');
        }

        if (template.layout === 1) { // aspectRatio is '16:9'
            const videoWidth = Math.min(width, height * 16 / 9);
            const videoHeight = videoWidth * 9 / 16;
            setVideoHeight(videoHeight)
            setVideoWidth(videoWidth)
        } else if (template.layout === 2) { // aspectRatio is '4:3'
            const videoWidth = Math.min(width, height * 4 / 3);
            const videoHeight = videoWidth * 3 / 4;
            setVideoHeight(videoHeight)
            setVideoWidth(videoWidth)
        } else if (template.layout === 3) { // aspectRatio is '3:2'
            const videoWidth = Math.min(width, height * 3 / 2);
            const videoHeight = videoWidth * 2 / 3;
            setVideoHeight(videoHeight)
            setVideoWidth(videoWidth)
        } else { // aspectRatio is '1:1'
            const size = Math.min(width, height);
            setVideoHeight(size)
            setVideoWidth(size)
        }
    }, [width, height, template]);

    useEffect(() => {
        setLoading(true);
        const apiService = new ApiService();

        // Fetching landing page details

        apiService.get('public/landing-page-details/' + id)
            .then(response => {
                if (response) {
                    const { landingPage, project } = response;
                    setTemplate(landingPage);
                    setProject(project);
                    
                    // Set video image URL
                    if (landingPage.imageUrl) {
                        setVideoImageUrl(landingPage.imageUrl);
                    } else if (project.imageUrl) {
                        setVideoImageUrl(project.imageUrl);
                    }

                    // Update meta tags with project image URL
                    updateMetaTags(project.imageUrl);
                }
            })
            .finally(() => setTimeout(() => setLoading(false), 100));
    }, [id]);

    const requestFullscreen = (element) => {
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) { /* Firefox */
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) { /* IE/Edge */
            element.msRequestFullscreen();
        }
    };

    const playVideo = () => {
        const player = document.getElementById("asdf41");
        const data = [
            { method: "setVolume", value: 0.5 },
            { method: "play" }
        ];
        data.forEach(d => player.contentWindow.postMessage(JSON.stringify(d), "*"))

        setStarted(true)

        // Entering fullscreen
        // https://github.com/vimeo/player.js/issues/52

        // All available methods:
        // https://developer.vimeo.com/player/sdk/reference
        // https://developer.vimeo.com/player/sdk
    }
    const videoLink = template?.videoLink; //?.replace('autoplay=1', '')

    const updateMetaTags = (imgUrl) => {
        setMetaTag('og:image', imgUrl);
        setMetaTag('twitter:image', imgUrl);
    };

    const setMetaTag = (name, content) => {
        let element = document.head.querySelector(`meta[property="${name}"]`);
        if (!element) {
            element = document.createElement('meta');
            element.setAttribute('property', name);
            document.head.appendChild(element);
        }
        element.setAttribute('content', content);
    };

    if (loading)
        return <div className='landing-page'>
            <div className='spinner-center'><Spinner /></div>
        </div>

    if (!template)
        return <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>ARE YOU
            LOST?</div>

    const handleOpenCtaButton = async (action) => {
        const token = await authService.getAccessToken();
        if (!token) {
            const apiService = new ApiService();
            // Fetching landing page details
            apiService.post('landingPage/' + id + "/click")
                .finally(() => window.open(action, '_blank'));
        } else {
            window.open(action, '_blank');
        }
    }

    
    const handlePlay = async () => {
        if (!isPlaying) {
            setIsPlaying(true);
            const token = await authService.getAccessToken();
            if (!token) {
                const apiService = new ApiService();
                // Fetching landing page details
                apiService.post(`landingPage/${id}/play-button-click`)
                    .finally(() => console.log('Play endpoint called'));
            } else {
                console.log('Token exists, no API call needed');
            }
        }
    };

    return (
        <div className='landing-page' style={{
            backgroundColor: template.primaryColor, ...(template.backgroundUrl && { background: `url(${template.backgroundUrl})` }),
            overflow: 'hidden'
        }}>
            <Row>
                <Col style={{
                    padding: 0,
                    paddingTop: videoTop,
                    textAlign: 'center',
                    backgroundColor: 'transparent',
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <ReactPlayer
                        url={videoLink}
                        playing={started}
                        controls={true}
                        light={videoImageUrl ?? true}
                        pip={true}
                        stopOnUnmount={false}
                        ref={playerRef}
                        onStart={() => requestFullscreen(playerRef.current.wrapper)}
                        width={videoWidth}
                        height={videoHeight}
                        onPlay={handlePlay}
                        playIcon={<PlayIcon />}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{ textAlign: 'center' }}>
                    {template.logoUrl &&
                        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
                            <img style={{ maxWidth: '300px', marginTop: '1rem' }} alt='Company' src={template.logoUrl} />
                        </div>
                    }
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '0 1rem' }}>
                        <pre className='message' style={{ color: template.secondaryColor }}>{template.message}</pre>
                    </div>
                </Col>
            </Row>
            <Row style={{ margin: ((template.embed1 || template.embed2) ? '1rem 0' : 0) }}>
                {template.embed1 && <Col
                    style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    {/* <b>Embed 1</b> */}
                    <iframe width={500} height={400} style={{ maxWidth: width }} src={template.embed1}
                        title='Embed'></iframe>
                </Col>}
                {template.embed2 && <Col
                    style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    {/* <b>Embed 2</b> */}
                    <iframe width={500} height={400} style={{ maxWidth: width }} src={template.embed2}
                        title='Embed'></iframe>
                </Col>}
            </Row>

            {template && template.ctaButtonAction && <Row>
                <Col style={{ textAlign: 'center', paddingBottom: '2rem', }}>
                    <Button className='slide-in'
                        onClick={() => handleOpenCtaButton(template.ctaButtonAction)}
                        style={{
                            backgroundColor: template.ctaButtonColor,
                            border: 'none',
                            boxShadow: '2px 2px 5px 1px #0002',
                            minWidth: '200px',
                            padding: '1rem'
                        }}>
                        {template.textCtaButton || 'CTA'}
                    </Button>
                </Col>


            </Row>}

            {/* FLIP PHONE PLAY ? */}
            <Row>
                <Col style={{ textAlign: 'center', paddingBottom: '0.5rem' }}>
                    <div style={{
                        display: 'inline-block',
                        textAlign: 'center',
                        padding: '0.5rem',
                        backgroundColor: '#f3f3f3',
                        borderRadius: '1rem',
                        boxShadow: '1px 1px 3px 1px #0003'
                    }}>
                        <a target='_blank'
                            href={'https://www.facebook.com/sharer/sharer.php?u=' + window.location.href}>
                            <img style={{ width: '2rem', height: '2rem', margin: '0.3rem' }} src='social/social_0.png' />
                        </a>
                        <a target='_blank' href={'https://twitter.com/home?status=' + window.location.href}>
                            <img style={{ width: '2rem', height: '2rem', margin: '0.3rem' }} src='social/social_1.png' />
                        </a>
                        <a target='_blank'
                            href={'https://www.linkedin.com/shareArticle?mini=true&url=' + window.location.href}>
                            <img style={{ width: '2rem', height: '2rem', margin: '0.3rem' }} src='social/social_2.png' />
                        </a>
                        <div style={{ padding: '0.5rem' }}>Dela din personliga film</div>
                    </div>
                </Col>
            </Row>
            {!started &&
                <div className='LandingPage-overlay' style={{ backgroundColor: template.primaryColor }}>
                    <h1 style={{ position: 'absolute', top: '4rem', fontWeight: 'bold' }}>Hej där!</h1>
                    <img style={{ maxWidth: '50vw', marginBottom: '1rem' }} alt='Company' src={template.logoUrl} />
                    <Button className='slide-in' onClick={playVideo} style={{
                        backgroundColor: template.secondaryColor,
                        border: 'none',
                        boxShadow: '2px 2px 5px 1px #0002'
                    }}>Starta min Video</Button>
                </div>
            }
        </div>
    );
}